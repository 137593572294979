import { CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { BpCoreComponent } from './bp-core.component';
import { BpBadgeDirective } from './directives/badge.directive';
import { DBpCalloutDirective } from './directives/callout.directive';
import { DBpCardDirective } from './directives/card.directive';
import { DBpGoalDetailCardDirective } from './directives/goal-detail-card.directive';
import { BpHeaderTitleDirective } from './directives/header-title.directive';
import { LinkDirective } from './directives/link.directive';
import { SkeletonDirective } from './directives/skeleton.directive';
import { BpTagDirective } from './directives/tag.directive';
import { TextValuesCardDirective } from './directives/text-values-card.directive';
import { BpTitleCardDirective } from './directives/title-card.directive';
import { TrackEventDirective } from './directives/track-event.directive';
import { BpTransitionDirective } from './directives/transition.directive';
import { AccountTypeFormat } from './pipes/account-type-format.pipe';
import { BankFormat } from './pipes/bank-format.pipe';
import { CustomCurrencyPipe } from './pipes/custom-currency.pipe';
import { FileSizePipe } from './pipes/file-size.pipe';
import { FinancialEntityEmailTypeEnumPipe } from './pipes/financial-entity-email-type-enum.pipe';
import { GoalTransactionStateEnumPipe } from './pipes/goal-transaction-state-enum.pipe';
import { GoalTransactionTypeEnumPipe } from './pipes/goal-transaction-type-enum.pipe';
import { IncomeRangePipe } from './pipes/income-range.pipe';
import { InternalNotificationneventConfigurationTypeEnumPipe } from './pipes/internal-notificationnevent-configuration-type-enum.pipe';
import { LogExternalTypeEnumPipe } from './pipes/log-external-type-enum.pipe';
import { OnlyNumberDirective } from './pipes/onlyNumber/only-number.directive';
import { OriginTypeEnumPipe } from './pipes/origin-type-enum.pipe';
import { PaymentMethodFormat } from './pipes/payment-method-format.pipe';
import { PrettyJsonPipe } from './pipes/pretty-json.pipe';
import { RemoveDashes } from './pipes/remove-dashes.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { TemplateTypeEnumPipe } from './pipes/template-type-enum.pipe';
import { TransactionGoalRequestTypeEnumPipe } from './pipes/transaction-goal-request-type-enum.pipe';
import { TransactionRequestStateEnumPipe } from './pipes/transaction-request-state-enum.pipe';
import { TransactionRequestTypeEnumPipe } from './pipes/transaction-request-type-enum.pipe';
import { TransactionRequestTypeFormat } from './pipes/transaction-request-type.pipe';
import { TransactionTypeFormat } from './pipes/transaction-type.pipe';

@NgModule({
  declarations: [
    BpCoreComponent,
    GoalTransactionStateEnumPipe,
    TransactionRequestStateEnumPipe,
    GoalTransactionTypeEnumPipe,
    LogExternalTypeEnumPipe,
    TransactionGoalRequestTypeEnumPipe,
    IncomeRangePipe,
    OriginTypeEnumPipe,
    InternalNotificationneventConfigurationTypeEnumPipe,
    TemplateTypeEnumPipe,
    OnlyNumberDirective,
    BankFormat,
    RemoveDashes,
    AccountTypeFormat,
    DBpCardDirective,
    DBpCalloutDirective,
    BpHeaderTitleDirective,
    SafeHtmlPipe,
    TransactionTypeFormat,
    BpTitleCardDirective,
    BpTransitionDirective,
    TextValuesCardDirective,
    LinkDirective,
    SkeletonDirective,
    DBpGoalDetailCardDirective,
    BpBadgeDirective,
    BpTagDirective,
    PaymentMethodFormat,
    CustomCurrencyPipe,
    TrackEventDirective,
    TransactionRequestTypeFormat,
    PrettyJsonPipe,
    TransactionRequestTypeEnumPipe,
    FinancialEntityEmailTypeEnumPipe,
    FileSizePipe,
  ],
  imports: [],
  exports: [
    BpCoreComponent,
    GoalTransactionStateEnumPipe,
    LogExternalTypeEnumPipe,
    TransactionRequestStateEnumPipe,
    GoalTransactionTypeEnumPipe,
    TransactionGoalRequestTypeEnumPipe,
    OriginTypeEnumPipe,
    InternalNotificationneventConfigurationTypeEnumPipe,
    TemplateTypeEnumPipe,
    OnlyNumberDirective,
    BankFormat,
    RemoveDashes,
    AccountTypeFormat,
    DBpCardDirective,
    DBpCalloutDirective,
    BpHeaderTitleDirective,
    SafeHtmlPipe,
    TransactionTypeFormat,
    BpTitleCardDirective,
    BpTransitionDirective,
    TextValuesCardDirective,
    LinkDirective,
    SkeletonDirective,
    DBpGoalDetailCardDirective,
    BpBadgeDirective,
    BpTagDirective,
    PaymentMethodFormat,
    CustomCurrencyPipe,
    TrackEventDirective,
    IncomeRangePipe,
    TransactionRequestTypeFormat,
    PrettyJsonPipe,
    TransactionRequestTypeEnumPipe,
    FinancialEntityEmailTypeEnumPipe,
    FileSizePipe,
  ],
  providers: [CurrencyPipe],
})
export class BpCoreModule {}
