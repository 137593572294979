import { Pipe, PipeTransform } from '@angular/core';
import { GoalTransactionPaymentMethod } from '../proto/goal-transaction-payment-enum.pb';

const PaymentMethodTranslations: Record<GoalTransactionPaymentMethod, string> = {
  [GoalTransactionPaymentMethod.TRANSFER]: 'Transferencia',
  [GoalTransactionPaymentMethod.CHECK]: 'Cheque',
  [GoalTransactionPaymentMethod.NONE]: 'Ninguno',
  [GoalTransactionPaymentMethod.REFERRAL]: 'Referencia',
  [GoalTransactionPaymentMethod.REBALANCE]: 'Rebalanceo',
  [GoalTransactionPaymentMethod.REINVEST]: 'Reinversión de caja',
  [GoalTransactionPaymentMethod.DIRECTTRANSFER]: 'Transferencia directa',
  [GoalTransactionPaymentMethod.REDISTRIBUTION]: 'Redistribución de caja',
};

@Pipe({
  name: 'paymentMethodFormat',
})
export class PaymentMethodFormat implements PipeTransform {
  transform(value: GoalTransactionPaymentMethod, ...args: any[]): string {
    return PaymentMethodTranslations[value] || 'Desconocido';
  }
}
