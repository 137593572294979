import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileSize',
})
export class FileSizePipe implements PipeTransform {
  // Factores de conversión (usando 1024 como base)
  private readonly units: { [unit: string]: number } = {
    B: 1,
    KB: 1024,
    MB: 1024 * 1024,
    GB: 1024 * 1024 * 1024,
    TB: 1024 * 1024 * 1024 * 1024,
  };

  /**
   * Transforma un tamaño numérico de archivo de una unidad a otra.
   *
   * @param value El valor numérico del tamaño.
   * @param inputUnit La unidad en la que está el valor de entrada (por defecto 'B').
   * @param outputUnit La unidad a la que se desea convertir (por defecto 'B').
   * @returns El valor convertido acompañado de la unidad de salida.
   *
   * Ejemplos de uso:
   *   {{ 2048 | libFileSize }} // "2048.00 B"
   *   {{ 2048 | libFileSize:'B':'KB' }} // "2.00 KB"
   *   {{ 1 | libFileSize:'MB':'GB' }} // "0.00 GB" (1 MB es 0.00097656 GB)
   */
  transform(value: number, inputUnit: string = 'B', outputUnit: string = 'B'): string {
    if (value == null || isNaN(value)) {
      return '';
    }

    const inUnit = inputUnit.toUpperCase();
    const outUnit = outputUnit.toUpperCase();

    const inputFactor = this.units[inUnit] || 1;
    const outputFactor = this.units[outUnit] || 1;

    // Convertir el valor a bytes
    const bytes = value * inputFactor;
    // Convertir de bytes a la unidad de salida deseada
    const result = bytes / outputFactor;

    // Se formatea el resultado a 2 decimales
    return `${result.toFixed(2)} ${outUnit}`;
  }
}
